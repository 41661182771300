<template>
  <div
    class="auth-layout-wrap demo"
    :style="{ backgroundImage: 'url(' + bgImage + ')' }"
  >
    <b-container style="max-width: 800px;">
      <b-row>
        <b-col class="text-center">
          <h1>Dashboard</h1>
        </b-col>
      </b-row>
      <b-row v-if="chart.series[0]">
        <b-col>
          <div class="measurement" v-bind:class="{disabled: !cT}" @click="cT=!cT; toggleMeasurements('gbdf_it_elsys_sensor_test/devices/pegasus004/up','payload_fields_temperature')">
            {{chart.series[1].data.filter(e=>e[1])[chart.series[1].data.filter(e=>e[1]).length-1][1].toFixed(2)}} <br />
            Temperatuur
          </div>
        </b-col>
        <b-col>
          <div class="measurement" v-bind:class="{disabled: !cH}" @click="cH=!cH; toggleMeasurements('gbdf_it_elsys_sensor_test/devices/pegasus004/up','payload_fields_humidity')">
            {{chart.series[2].data.filter(e=>e[1])[chart.series[2].data.filter(e=>e[1]).length-1][1].toFixed(2)}} <br />
            Luchtvochtigheid
          </div>
          
        </b-col>
        <b-col>
          <div class="measurement" v-bind:class="{disabled: !cC}" @click="cC=!cC; toggleMeasurements('gbdf_it_elsys_sensor_test/devices/pegasus004/up','payload_fields_co2')">
            {{chart.series[3].data.filter(e=>e[1])[chart.series[3].data.filter(e=>e[1]).length-1][1].toFixed(2)}} <br />
            CO2
          </div>
          
        </b-col>
        <b-col>
          <div class="measurement" v-bind:class="{disabled: !cL}" @click="cL=!cL; toggleMeasurements('gbdf_it_elsys_sensor_test/devices/pegasus004/up','payload_fields_light')">
            {{chart.series[1].data.filter(e=>e[1])[chart.series[1].data.filter(e=>e[1]).length-1][1].toFixed(2)}} <br />
            Light
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card class=" mb-30">
            <b-input-group v-if="uO && deviceSelected && deviceSelected.length > 0" >
                <b-form-select class="m-1" v-model="serieSelectedButtons" :options="deviceSelected"></b-form-select>
                <b-button @click="toggleMeasurements(serieSelectedButtons, item.name)" class="m-1" variant="primary" 
                    v-bind:class="{ 'btn-info' : item.disabled, 'btn-primary': !item.disabled}" size="sm"
                    v-for="(item, index) in seriesButtons[serieSelectedButtons]" :key="index">
                    {{item.name}}
                </b-button>
            </b-input-group>
            <highcharts :constructorType="'stockChart'" class="hc" :options="chart" ref="chart" style="height: calc(100vh - 20em)"></highcharts>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock"
import Exporting from "highcharts/modules/exporting"
Stock(Highcharts);
Exporting(Highcharts);
import {Chart} from 'highcharts-vue';

//import Multiselect from 'vue-multiselect';
//import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  metaInfo: {
    title: "Sensors dashboard"
  },
  components: {
      highcharts : Chart,
      //Multiselect
  },
  data: () => {
      return {
        sensors: [],
        avg_temperature: 0,
        avg_humidity: 0,
        bgImage: require("@/assets/images/background-login.jpg"),
        logo: require("@/assets/images/logo-inverse.png"),
        devices: [],
        deviceSelected: null,
        cC: true,
        cT: true,
        cL: true,
        cH: true,
        chart: {
            series: [
                
            ],
            xAxis: {
                type: 'datetime',
            },
            navigation: {
                buttonOptions: {
                    theme: {
                        // Good old text links
                        style: {
                            color: '#039',
                            textDecoration: 'underline'
                        }
                    }
                }
            },
        },
        seriesButtons: [],
        serieSelectedButtons: null,
        uO: true
      };
  },
  watch: {
      deviceSelected(currentValue, oldValue) {
          let removed = oldValue != null && currentValue.length < oldValue.length;
          let changed = oldValue != null ? (!removed ? currentValue.filter(x => !oldValue.includes(x))[0] : oldValue.filter(x => !currentValue.includes(x))[0]) : currentValue[0];
          if(removed) {
              this.toggleDevice(changed);
          } else {
              this.addDevice(changed);
          }
      }
  },
  mounted() {
    this.addDevice("gbdf_it_elsys_sensor_test/devices/pegasus004/up");
  },
  methods: {
      toggleMeasurements(serie, measurement) {
        this.$refs.chart.chart.series.forEach((ele) => {
            if (ele.options.device == serie && ele.options.measurement == measurement) {
                !ele.visible ? ele.show(): ele.hide()
            }
        });
      },
      toggleDevice(deviceName) {
        for(let i=0; i<this.$refs.chart.chart.series.length; i++){
            if(this.$refs.chart.chart.series[i].options.device == deviceName) {
                let serie = this.$refs.chart.chart.series[i];
                if(serie.visible) {
                    serie.show();
                    this.$toast.info("Enabled device on graph.");
                } else {
                    serie.hide();
                    this.$toast.info("Disabled device on graph.");
                }
                return true;
            }
        }
        return false;
      },
      addDevice(deviceName) {
          let _this = this;
          if( this.toggleDevice(deviceName) ) return;

          //Device not toggled, so not yet in memory, loading new one
          this.$toast.info("Loading sensor data.");
          this.$http.get("/sensors/demo",{
              params: {
                  "device": deviceName
              }
          }).then(function(result) {
            if(result.data.status == "success") {
                const item = result.data.sensor;

                console.log("Sensor type: " + item.type);
                let measurements = JSON.parse(item.measurements);

                measurements.forEach((measurement) => {
                  if(measurement != "payload_fields_vdd" && measurement != "payload_fields_motion") {
                    if(!_this.seriesButtons[deviceName]) {
                        _this.seriesButtons[deviceName] = [];
                    }
                    _this.seriesButtons[deviceName].push({name: measurement, disabled: false});
                    
                    let data = item.points.map(a => [new Date(a.time).getTime(), a[measurement]]);

                    console.log(_this.chart.series.length, measurement);
                    _this.chart.series.push(
                    {
                        device: deviceName,
                        measurement: measurement,
                        name: deviceName + " " + measurement,
                        data: data,
                        type: 'line',
                        smooth: true,
                        symbolSize: 8,
                        lineStyle: {
                            color: '#ff5721',
                            opacity: 1,
                            width: 1.5,
                        },
                        itemStyle: {
                            color: '#fff',
                            borderColor: '#ff5721',
                            borderWidth: 1.5
                        }
                    });

                  }
                });
                _this.$toast.success("Sensor data added.");
            }
            });
        }
    }
};
</script>

<style>
.spinner.sm {
  height: 2em;
  width: 2em;
}

.demo {
  margin-top:2em;
  padding-top:1em;
  border-radius: 15px;
  justify-content:flex-start;
}

.measurement {
  background: rgba(143, 188, 143, 0.8) !important;
  border-radius: 10px;
  padding: 1em;
  text-align: center;
  padding-top: 2em;
  padding-bottom: 2em;
  cursor: pointer;
  margin-bottom: 1em;
  transition: all 0.2s ease-in;
}

.measurement.disabled {
  background: rgba(60, 60, 60, 0.5) !important;
}

.measurement:hover {

  background: rgba(143, 188, 143, 1) !important;

}
</style>
